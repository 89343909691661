.App {
  font-family: sans-serif;
  text-align: center;
  justify-content: center;
  display: grid;
}

.App1 {
  max-width: 400px; margin: auto; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 8px; overflow: hidden;
}
.App1 h1 {
  background-color: #a0d2eb; 
        color: white; 
        margin: 0; 
        padding: 10px 15px; 
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        border-bottom: 1px solid #d3d3d3;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
